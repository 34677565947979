import { render } from "./Discover.vue?vue&type=template&id=6ca22682&scoped=true"
import script from "./Discover.vue?vue&type=script&lang=js"
export * from "./Discover.vue?vue&type=script&lang=js"

import "./Discover.vue?vue&type=style&index=0&id=6ca22682&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-6ca22682"

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QBtn,QScrollArea,QList,QItemLabel,QItem,QItemSection,QCheckbox,QSeparator,QOptionGroup,QSelect,QInfiniteScroll,QCard,QImg,QCardSection,QAvatar,QIcon,QSpinnerDots,QPageSticky});qInstall(script, 'directives', {Ripple});
