<template>
  <div class="q-page">
    <div class="row text-center">
      <div class="col-12">
        <h2>Welcome to EZVERSE!</h2>
      </div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
</style>

<script>
import { supabase as s } from "../supabase";
import { ref, onBeforeMount, inject } from "vue";

import { useRoute } from "vue-router";
import { likeit } from "../web3";

export default {
  components: {

  },
  name: "AllNFTs",
  setup() {
    const user = ref(s.auth.user());
    const profile = inject("profile");
    onBeforeMount(async () => {
      // set refer to local storage, send to server when user signin
      // if local has already has a referer or currently exist login user don't need set referer
      // only set referer for the clean new user first visit
      if (!localStorage.getItem("referer") && !user.value) {
        // query refer user info
        if (useRoute().query.rid == undefined) {
          console.log("No referer id, not a referer page");
        } else {
          const rid = useRoute().query.rid;
          console.log("referer uid", rid);
          let { data: u, error } = await s
            .from("users")
            .select("*")
            .eq("id", rid);
          if (error) console.log(error);
          else {
            console.log("Query refer user:", u[0]);
            localStorage.setItem("referer", u[0].id);
            console.log("set local referer id:", u[0].id);
          }
        }
      }
    });

    return {
      likeit,
      profile,
      user,
    };
  },

  methods: {
  },
};
</script>
