// Javascript 

import Web3 from "web3"
import Wallet from 'ethereumjs-wallet'
import { supabase as s } from "./supabase"

//export const chainurl = "https://polygon-rpc.com/"
export const chainurl = "https://polygon-mainnet.g.alchemy.com/v2/H3eM8KOL13XHGFytwKlaDhDS9YcsqQIT/"

export const chainscan = "https://polygonscan.com/"
//export const w3 = new Web3('https://rpc-mumbai.matic.today')
export const w3 = new Web3(chainurl)

console.log("web3", w3)


export const create_wallet = () => {
    return Wallet.generate()
}

let api_url = "https://cnft.us:5000"
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    api_url = "http://localhost:8081"
}
export const APIURL = api_url

let web_root = "https://" + location.hostname
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    web_root = "http://localhost:8080"
}
export const WEBROOT = web_root

let redirect_url = "https://" + location.hostname
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    redirect_url = "http://localhost:8080"
}
export const REDIRECT_URL = redirect_url


export const next_tid = () => {
    let hexbn = Web3.utils.randomHex(32)
    let tid = Web3.utils.hexToNumberString(hexbn)
    console.log("Next TokenID:", tid)
    return tid;
}

export const next_token_id = async (contract) => {
    const { data, error, count } = await s
        .from("tokens")
        .select("id", { count: "exact" })
        .eq("contract", contract);
    return count + 1;
}


const member_contract = "0x3324F46D3E05ADC9B0395769113264aa2749E372";
const cnft_abi = require('./assets/cnft.abi.json');

const cnft = new w3.eth.Contract(cnft_abi, member_contract);
export const levels = { 0: '', 1: 'Bronze', 2: 'Silver', 3: 'Gold', 4: 'Ruby' };

export const get_member_level = async (addr) => {
    console.log("get_member_level:", addr)
    let levels = await cnft.methods.balanceOfBatch(
        [addr, addr, addr, addr],
        [1, 2, 3, 4]).call()
    console.log("levels", levels)
    if (parseInt(levels[3]) > 0) return 4
    if (parseInt(levels[2]) > 0) return 3
    if (parseInt(levels[1]) > 0) return 2
    if (parseInt(levels[0]) > 0) return 1
    return 0
}

export const likeit = async (id) => {
    const { data, error } = await s.rpc("like", { row_id: id });
    if (error) {
        console.log("Like it error:", error)
    } else {
        console.log("Like it success:")
    }
    return false
}


export const share_to = (title, desc, share_url) => {
    let lang = window.localStorage.getItem("lang");

    if (lang === "undefined") {
        lang = 'en'
    }
    // if(lang=="en") {
    //     alert("New user gets 500 CNFTG token and introducer gets 200 CNFTG tokens!")
    // } else {
    //     alert("新会员获得500平台CNFTG币，介绍人获得200平台CNFTG币!")
    // }
    if(String(share_url).indexOf('?') > 0) {
        share_url = share_url + "&lang=" + lang;
    } else {
        share_url = share_url + "?lang=" + lang;
    }
    

    if (navigator.share) {
        navigator.share({
            title: title,
            text: desc,
            url: share_url
        })
            .then(() => console.log('Successful share'))
            .catch(error => console.log('Error sharing:', error));
    } else {
        /* Get the text field */
        var copyText = document.getElementById("my_share_url");
        copyText.value = share_url;

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        alert("Copied the share link, please share!: " + copyText.value);
    }
}