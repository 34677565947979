<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated>
      <q-toolbar class="q-ma-xs">
        <q-btn stretch flat to="/">
          <q-toolbar-title>
            <img
              class="my-logo"
              src="@/assets/logo.png"
            />
          </q-toolbar-title>
        </q-btn>

        <q-input
          class="hidden"
          dense
          rounded
          standout
          v-model="kword"
          input-class="text-left"
          size="25"
          style="font-size: 24px"
        >
          <template v-slot:append>
            <q-icon v-if="kword === ''" name="search" />
            <q-icon
              v-else
              name="clear"
              class="cursor-pointer"
              @click="kword = ''"
            />
          </template>
        </q-input>
        <q-space />

        <br />
        <q-space />
        <div class="gt-sm"></div>
        <q-btn-dropdown class="q-ma-none q-pa-sm" id="my-btn-lang" flat rounded>
          <template v-slot:label>
            <q-avatar size="26px">
              <img src="/img/icons/language.svg" />
            </q-avatar>
          </template>

          <q-list>
            <q-item
              class="myitem"
              clickable
              v-close-popup
              @click="set_lang('en')"
            >
              <q-item-section>
                <q-item-label>English</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              class="myitem"
              clickable
              v-close-popup
              @click="set_lang('zh_CN')"
            >
              <q-item-section>
                <q-item-label>中文</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn
          flat
          round
          icon="account_balance_wallet"
          v-on:click="show_wallet()"
        />
      </q-toolbar>
    </q-header>
    <!-- wallet drawer -->
    <q-drawer v-if="mysession" v-model="right_drawer" side="right" bordered>
      <div class="absolute" style="top: 80px; left: -17px">
        <q-btn
          v-if="right_drawer"
          dense
          round
          unelevated
          color="secondary"
          icon="chevron_right"
          @click="right_drawer = false"
        />
      </div>
      <!-- drawer content -->
      <div class="q-pa-md q-mt-lg row items-center">
        <div class="col">
          <q-avatar size="44px" class="float-left q-mr-md">
            <img :src="myprofile.avatar" />
          </q-avatar>
          <span>
            {{ myprofile.nickname }}

            <br />
            {{ $t(level_str) }}
          </span>
        </div>
        <div class="col">
          <q-toggle
            class="float-right"
            v-model="is_dark"
            color="secondary"
            icon="dark_mode"
          />
        </div>
      </div>
      <div class="q-ma-lg text-center">
        <q-btn
          color="orange"
          rounded
          class="full-width q-pt-md q-pb-md"
          :label="$t('my_nft')"
          @click="mynft()"
        ></q-btn>
        <input hidden type="text" :value="share_url" id="my_share_url" />
        <q-btn class="q-mt-lg" flat rounded @click="share()">{{$t('share')}}</q-btn>
      </div>
      <div class="q-pa-lg">
        <q-input
          readonly
          v-model="cur_user.user_metadata.wallet"
          :label="$t('wallet_address')"
        >
          <template v-slot:append>
            <q-icon
              name="content_copy"
              @click="copy(cur_user.user_metadata.wallet)"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>

      <div class="q-pa-lg">
        <q-input
          readonly
          v-model="cur_user.user_metadata.key"
          :label="$t('wallet_key')"
          @click="show_type = 'text'"
          :type="show_type"
        >
          <template v-slot:append>
            <q-icon
              name="content_copy"
              @click="copy(cur_user.user_metadata.key)"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>

      <div class="q-ma-md q-pa-md my-balance">
        <q-avatar size="44px" class="float-left q-mr-md">
          <img src="@/assets/matic.png" />
        </q-avatar>
        <span class="text-grey">MATIC {{$t('balance')}}</span><br />
        <span class="text-bold text-body2">{{ financial(matic_balance) }}</span>
      </div>
      <div class="q-ma-lg text-center">
        <q-btn
          color="secondary"
          rounded
          class="full-width my-buy-cnftg q-pt-md q-pb-md"
          :label="$t('logout')"
          @click="logout()"
        ></q-btn>
      </div>
    </q-drawer>
    <q-page-container>
      <router-view :key="$route.fullPath" />
    </q-page-container>
  </q-layout>
</template>
<style lang="sass">
*
  font-family: Urbanist
  font-style: normal
  text-decoration: none
  .nft
    cursor: pointer
  .flink
    font-size: 14px
    font-weight: 400
  a, a:hover, a:focus, a:active
    text-decoration: none
    color: grey

.my-logo
  height: 1.5rem
  vertical-align: middle

.my-nested
  max-width: 1250px
  margin-left: auto
  margin-right: auto

.my-title
  margin: 1rem
  font-size: 6vh

.my-notify
  font-size: 3rem
  background-color: #000

#my-btn-lang
  i
    display: none

#my-btn-menu
  i
    display: none

.my-balance
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  border-radius: 13px
  padding: 1rem

.my-buy-cnftg
  background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%)
  border-radius: 13px

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
<script>
import { provide, ref } from "vue";
import { supabase } from "./supabase";
import { useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";
import { levels } from "./web3.js";
import axios from "axios";
import { copyToClipboard } from "quasar";
import { share_to} from "./web3.js";

export default {
  name: "App",
  setup(props, context) {
    const show_menu = ref(false);
    const show_banner = ref(true);
    const balance = ref(0);
    const mysession = ref(null);
    const myprofile = ref({});
    const cur_user = ref(null);
    const cnftg_balance = ref(0);
    const mine_interval = ref(300);
    const timer_value = ref(0);
    const router = useRouter();
    const lang = ref(window.localStorage.getItem("lang"));
    const rid = ref("");
    const $q = useQuasar();
    const matic_balance = ref(0);
    const cbalance = ref(0);
    const is_dark = ref(true);
    const level_str = ref("");
    const wallet = ref("");
    provide("profile", myprofile);
    provide("user", cur_user);
    provide("q", $q);

    let local_is_dark = window.localStorage.getItem("is_dark");
    console.log("read local dark mode:", local_is_dark);
    if (local_is_dark == 1) {
      is_dark.value = true;
      $q.dark.set(true);
    } else if (local_is_dark == 0) {
      is_dark.value = false;
      $q.dark.set(false);
    } else {
      is_dark.value = true;
      $q.dark.set(true);
    }

    // set status
    // or false or "auto"
    // code like shit, stupid router can not return query params here!!!
    var queries = {};
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        queries[item.split("=")[0]] = item.split("=")[1];
      });
      console.log(queries);
      
    if ('lang' in queries) {
      lang.value = queries["lang"];
      if (lang.value == "en" || lang.value == "zh_CN") {
        window.localStorage.setItem("lang", lang.value);
        console.log("Get lang from url:", lang.value);
      }      
    }

    if ('rid' in queries) {
      rid.value = queries["rid"];
    }
    if ('w' in queries) {
      wallet.value = queries["w"];
    } 
    const toggle_menu = () => {
      show_menu.value = !show_menu.value;
      console.log("dismiss", show_banner);
    };

    // const mining_timer = async () => {
    //   if (!mysession.value) {
    //     return;
    //   }
    //   timer_value.value++;
    //   if (timer_value.value > 100) {
    //     timer_value.value = 0;
    //     // mine one cnftg
    //     const { data, error } = await supabase.rpc("mine", {
    //       uid: myprofile.value.id,
    //     });
    //     console.log("Call mine return:", data);
    //     balance.value += data;
    //   }
    // };

    const dismiss = () => {
      show_banner.value = ref(true);
      console.log("dismiss", show_banner);
    };

    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Trigger onAuthStateChange");
      if (event == "SIGNED_OUT") {
        console.log("SIGNED_OUT", session);
      }

      if (event == "SIGNED_IN") {
        console.log("SIGNED_IN", session);
        mysession.value = session;
        cur_user.value = session.user;

        // top priority
        // if user has no wallet, create it
        if (undefined == cur_user.value.user_metadata.wallet || "" == cur_user.value.user_metadata.wallet ) {
          console.log("New user, no wallet");
          // save user input wallet
          const { user, error } = await supabase.auth.update({
            data: {
              wallet: wallet.value,
              key: ""
            },
          });
          if (error) {
            console.log("Set user wallet failed! supabase.auth.update");
          } else {
            console.log("Updated user info:", user);
            cur_user.value = user;
            alert(
              "Set Your Polygon wallet address to:" +
              wallet.value
            );            
          }
        } else {
          console.log("has wallet:", cur_user.value.user_metadata.wallet);
          // alert(
          //   "Your current Polygon wallet address:" +
          //   cur_user.value.user_metadata.wallet
          // );
        }

        // insert profile record if not exist
        let { data: profile, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", session.user.id);
        if (error) console.log(error);
        if (profile.length == 0) {
          let { data: newprofile, error } = await supabase
            .from("profiles")
            .insert({
              id: session.user.id,
            });
          if (error) console.log(error);
          myprofile.value = newprofile;
          console.log("Add new profile", newprofile);
        } else {
          console.log("Get profile:", profile);
          myprofile.value = profile[0];
          provide("profile", myprofile.value);
          level_str.value = levels[profile[0].ulevel];
        }

        // Set referid when login
        console.log("get rid from url:");
        if (rid.value) {
          const oldrid = myprofile.value.rid;
          // record referer
          console.log("old rid:", oldrid);
          if (!oldrid) {
            console.log(
              "Try update user",
              session.user.id,
              " referer:",
              rid.value
            );

            const { data, error } = await supabase
              .from("profiles")
              .update({ rid: rid.value })
              .match({ id: session.user.id });
            if (error) console.log(error);
            console.log("Update referer id to profile:", data);
          } else {
            console.log(
              "Try update rid to:",
              rid.value,
              " but referer is existed:",
              oldrid,
              " Skipped"
            );
          }
        } else {
          console.log("No rid, need not set referer user.");
        }
      }
    });

    return {
      right_drawer: ref(true),
      cnftg_balance,
      timer_value,
      cur_user,
      show_menu,
      show_banner,
      toggle_menu,
      dismiss,
      balance,
      mysession,
      myprofile,
      mine_interval,
      lang,
      kword: ref(""),
      cbalance,
      matic_balance,
      copyToClipboard,
      $q,
      show_type: ref("password"),
      levels,
      is_dark,
      level_str,
      share_to,
      share_url:ref("")
    };
  },
  watch: {
    is_dark: function (v, e) {
      if (v) {
        window.localStorage.setItem("is_dark", 1);
      } else {
        window.localStorage.setItem("is_dark", 0);
      }
      this.$q.dark.set(v);
    },
  },

  mounted: function () {
    if (this.lang == "en" || this.lang == "zh_CN") {
      window.localStorage.setItem("lang", this.lang);
      console.log("Get lang from url:", this.lang);
      this.set_lang(this.lang);
    } else {
      this.lang = window.localStorage.getItem("lang");
      this.set_lang(this.lang);
    }
  },
  beforeUpdate: function () {
    console.log("get params in activated:", this.$route.query);
  },

  methods: {
    set_lang(e) {
      console.log("set lang to:",e);
      this.$i18n.locale = e;
      window.localStorage.setItem("lang", e);
    },

    login() {
      console.log("call login");
      console.log("src:", window.location.pathname);
      this.$router.push({
        path: "/login",
        query: {
          src: window.location.pathname,
        },
      });
    },
    mynft() {
      this.$router.push({
        path: "/mynfts"
      });
    },
    async logout() {
      this.cur_user = null;
      await supabase.auth.signOut();
      window.location.reload();
      console.log("logout");
    },
    show_wallet() {
      if (this.mysession) {
        this.right_drawer = !this.right_drawer;
      } else {
        this.$router.push({
          path: "/login",
          query: {
            src: window.location.pathname,
          },
        });
      }
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("en-US").format(
        date
      );
    },

    financial(x) {
      return Number.parseFloat(x).toFixed(4);
    },

    share() {
      var url = window.location;
      this.share_url = url;
      var host = window.location.hostname;
      share_to(host, "NFT Market", url);
    },
  },
};
</script>
