import { createRouter, createWebHistory } from 'vue-router'
import { supabase as s } from '../supabase'
import { useRoute } from 'vue-router'
import Home from '../views/AllNFTs.vue'
import Discover from '../views/Discover.vue'

const routes = [


  {
    path: '/',
    name: 'Home',
    component: Discover
    // component: () => import(/* webpackChunkName: "allnfts" */ '../views/AllNFTs.vue')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   // component: Home
  //   component: () => import(/* webpackChunkName: "upgrade" */ '../views/Upgrade.vue')
  // },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/faqcn',
    name: 'FAQCN',
    component: () => import(/* webpackChunkName: "faqcn" */ '../views/FAQ_zh_CN.vue')
  },
  {
    path: '/discover',
    name: 'Discover',
    component: () => import(/* webpackChunkName: "discover" */ '../views/Discover.vue')
  },
  {
    path: '/recent',
    name: 'RecentNFT',
    component: () => import(/* webpackChunkName: "recentnft" */ '../views/RecentNFT.vue')
  },
  {
    path: '/audit',
    name: 'Audit',
    component: () => import(/* webpackChunkName: "audit" */ '../views/Audit.vue'),
    meta: { auth: true }
  },
  {
    path: '/orders',
    name: 'OrdersAudit',
    component: () => import(/* webpackChunkName: "orderaudit" */ '../views/OrderAudit.vue'),
    meta: { auth: true }
  },
  {
    path: '/nft/:id',
    name: 'NFT',
    component: () => import(/* webpackChunkName: "nft" */ '../views/NFT.vue')
  },

  {
    path: '/profile/:uid',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },

  {
    path: '/mynfts',
    name: 'MyNFTs',
    component: () => import(/* webpackChunkName: "mynfts" */ '../views/MyNFTs.vue'),
    meta: { auth: true }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "member" */ '../views/Member.vue'),
    meta: { auth: false }
  },
  {
    path: '/shop/:uid',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
  },

  {
    path: '/myorders',
    name: 'MyOrders',
    component: () => import(/* webpackChunkName: "myorders" */ '../views/MyOrders.vue'),
    meta: { auth: true }
  },
  {
    path: '/mysales',
    name: 'MySales',
    component: () => import(/* webpackChunkName: "mysales" */ '../views/MySales.vue'),
    meta: { auth: true }
  },
  {
    path: '/mywallet',
    name: 'MyWallet',
    component: () => import(/* webpackChunkName: "mywallet" */ '../views/MyWallet.vue'),
    meta: { auth: true }
  },
  {
    path: '/mysettings',
    name: 'MySettings',
    component: () => import(/* webpackChunkName: "mysettings" */ '../views/MySettings.vue'),
    meta: { auth: true }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },

  {
    path: '/mint',
    name: 'mint',
    component: () => import(/* webpackChunkName: "mint" */ '../views/Mint.vue'),
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue')
  },
  {
    path: '/r/:rid',
    name: 'Referer',
    component: () => import(/* webpackChunkName: "referer" */ '../views/Referer.vue')
  },
  {
    path: '/team/:uid',
    name: 'Team',
    component: () => import(/* webpackChunkName: "myteam" */ '../views/MyTeams.vue'),
    meta: { auth: true }
  }, {
    path: '/edit/:id',
    name: 'NFTEdit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: '/checkout/:oid',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../components/Checkout.vue'),
    meta: { auth: true }
  },
  {
    path: '/confirm/:id',
    name: 'OrderConfirm',
    component: () => import(/* webpackChunkName: "orderconfirm" */ '../components/OrderConfirm.vue'),
    meta: { auth: true }
  },

  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "allnfts" */ '../views/AllNFTs.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const u = s.auth.user()



  if (to.meta.auth && to.name !== 'Login' && !u) next({ name: 'Login' })
  else next()
})
export default router
