<template>
  <q-drawer class="q-mt-lg" v-model="left_drawer" side="left">
    <div class="absolute" style="top: 80px; right: -17px">
      <!--v-if="left_drawer && !q.platform.is.desktop"-->
      <q-btn
        dense
        v-if="false"
        round
        unelevated
        color="secondary"
        icon="chevron_left"
        @click="left_drawer = false"
      />
    </div>
    <q-scroll-area class="fit">
      <!-- drawer content -->
      <div class="q-ml-md">
        <q-list padding>
          <q-item-label header>Featured Filter</q-item-label>
          <q-item tag="label" v-ripple>
            <q-item-section side top>
              <q-checkbox v-model="editor_sel" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Editor Selected</q-item-label>
            </q-item-section>
          </q-item>

          <q-item tag="label" v-ripple>
            <q-item-section side top>
              <q-checkbox v-model="most_liked" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Most Liked</q-item-label>
            </q-item-section>
          </q-item>

          <q-separator spaced />

          <q-item-label header>Status</q-item-label>

          <q-item tag="label" v-ripple>
            <q-item-section>
              <q-option-group
                :options="price_options"
                type="radio"
                v-model="price"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-scroll-area>
  </q-drawer>

  <!-- <div class="q-pa-md row items-center">
    <div class="col text-h5">{{total}} items</div>
    <div class="col">
      <q-select color="secondary" v-model="sort_by" :options="options" label="Sort By" />
    </div>
  </div> -->
  <!-- NFT List -->
  <q-infinite-scroll
    class="q-ma-md row"
    @load="my_load"
    :offset="250"
    ref="my_scroll"
  >
    <div
      v-for="(k, i) in allnfts"
      :key="i"
      class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 q-pa-xs"
    >
      <q-card class="my-card">
        <a :href="'/nft/' + k.id">
          <q-img
            class="my-card-img q-pa-md"
            ratio="1"
            :src="k.data.image"
            :alt="k.data.description"
          >
            <q-img
              v-if="k.total - k.token_sum <= 0"
              class="absolute-center"
              src="@/assets/soldout.png"
            />
          </q-img>
        </a>
        <q-card-section style="padding: 5px 5px 15px 5px">
          <div class="my-nft-name">{{ k.data.name }}</div>

          <div class="row q-mt-md items-center">
            <div class="col-2">
              <q-avatar rounded style="border-radius: 18px">
                <img :src="k.uowner.avatar" />
              </q-avatar>
            </div>
            <div class="col q-ml-md">
              <span color="grey ">{{$t('creator')}} </span> <br />
              <span class="truncate">{{ k.uowner.nickname }}</span>
            </div>
            <div class="col">
              <span
                class="
                  bg-secondary
                  q-pl-xs q-pr-xs q-pt-none q-pb-none
                  float-right
                "
                style="border-radius: 5px"
              >
                <q-icon color="red" size="16px" name="favorite" />
                <span class="text-weight-bold" style="font-size: 12px">{{
                  k.likes
                }}</span>
              </span>
            </div>
          </div>
          <hr style="border: 1px solid #14141f" />
          <div class="row">
            <div class="col text-left">
              <span class="text-grey">{{ $t("price") }}</span>
              <br />
              <span class="text-bold">${{ k.data.price }} </span>
            </div>
            <div class="col text-center">
              <span class="text-grey">{{ $t("total") }}</span>
              <br />
              <span class="text-bold">{{ k.total }}</span>
            </div>
            <div class="col text-right">
              <span class="text-grey">{{ $t("remains") }}</span>
              <br />
              <span class="text-bold">{{
                k.total - k.token_sum > 0 ? k.total - k.token_sum : 0
              }}</span>
            </div>
          </div>
          <div class="text-body float-right text-grey">
            {{ pdate(k.created_at) }}
          </div>
        </q-card-section>

        <q-img
          v-if="k.data.price == 0"
          width="4vw"
          class="absolute-top-right"
          src="@/assets/free.png"
        />
      </q-card>
    </div>
    <template v-slot:loading>
      <div class="row justify-center q-my-md">
        <q-spinner-dots color="primary" size="40px" />
      </div>
    </template>
  </q-infinite-scroll>

  <!-- <q-page-sticky
    v-if="!q.platform.is.desktop"
    position="bottom-left"
    :offset="[18, 18]"
  >
    <q-btn
      text-color="white"
      fab
      icon="filter_list"
      color="secondary"
      class="q-mr-md"
      @click="left_drawer = !left_drawer"
    />
  </q-page-sticky> -->
</template>
<style lang="sass" scoped>
.my-card
  border-radius: 15px
  padding: 10px
  margin-bottom: 10px
  .my-nft-name
    font-size: 18px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .my-card-img
    border-radius: 15px
</style>

<script>
import router from "@/router";
import { supabase as s } from "../supabase";
import { ref, inject, onBeforeMount, onMounted } from "vue";
import { useQuasar } from "quasar";

export default {
  name: "Audit",
  // components: {
  //   // InfiniteLoading,
  // },
  setup(props) {
    const editor_sel = ref(false);
    const most_liked = ref(false);
    const buy_now = ref(false);
    const free = ref(false);
    const free_seller = ref(false);
    const bronze_seller = ref(false);
    const silver_seller = ref(false);
    const gold_seller = ref(false);
    const ruby_seller = ref(false);
    const price = ref("");
    const sort_by = ref("");
    const left_drawer = ref(true);
    const $q = useQuasar();
    const total = ref(0);
    console.log("$q", $q.platform);
    if (!$q.platform.is.desktop) {
      console.log("This is mobile");
      left_drawer.value = false;
    }
    left_drawer.value = false;

    let allnfts = ref([]);
    let page_size = 50;

    let my_load = (index, done) => {
      console.log("page:", index);
      var flag = 0;
      var h = window.location.hostname
      if ( h == "nft.ezverse.io") {
        flag = -120;
      } else if (h == "nft.ufa.pub") {
        flag = -150;
      } else if (h == "nft.konectible.com") {
        flag = -110;
      } else if (h == "nft.wewin.net") {
        flag = -130;
      } else if (h == "nft.cnft.us") {
        flag = -140;
      } else if (h == "nft.ezmeta.io") {
        flag = -160;
      }
      let morenfts = s
        .from("v_nfts")
        .select("*,uowner:uowner(*)",{ count: 'exact' })
        .range(page_size * (index - 1), page_size * index - 1);
      if (editor_sel.value) {
        console.log("use filter: editor select");
        morenfts = morenfts.gt("flag", 0);
      } else {
        morenfts = morenfts.eq("flag", flag);
      }
      if (most_liked.value) {
        morenfts = morenfts.gt("likes", 1000);
      }
      if (price.value == "buynow") {
        morenfts = morenfts.gt("data->price", 0);
      }
      if (price.value == "free") {
        morenfts = morenfts.eq("data->price", 0);
      }

      if (free_seller.value) {
        morenfts = morenfts.eq("uowner.ulevel", 0);
      }
      if (bronze_seller.value) {
        morenfts = morenfts.eq("uowner.ulevel", 1);
      }
      if (silver_seller.value) {
        morenfts = morenfts.eq("uowner.ulevel", 2);
      }
      if (gold_seller.value) {
        morenfts = morenfts.eq("uowner.ulevel", 3);
      }
      if (ruby_seller.value) {
        morenfts = morenfts.eq("uowner.ulevel", 4);
      }
        console.log(sort_by.value)

      if (sort_by.value) {
        switch(sort_by.value.value) {
          case "recent":
            morenfts = morenfts.order("created_at", { ascending: false });
            break;
          case "oldest":
            morenfts = morenfts.order("created_at", { ascending: true });
            break;
          case "l2h":
            morenfts = morenfts.order("data->price", { ascending: true });
            break;
          case "h2l":
            morenfts = morenfts.order("data->price", { ascending: false });
            break;
          case "most_like":
            morenfts = morenfts.order("likes", { ascending: false });
            break;
          case "least_like":
            morenfts = morenfts.order("likes", { ascending: true });                        
            break;                       
          default:
            console.log("Error, no value")
            break;
        }
      }
      morenfts = morenfts.order("created_at", { ascending: false });
      morenfts.then((res) => {
        const json = res.data;
        console.log(json);
        if (!json || json.length == 0) {
          console.log("No more record, stop request");
          done(true);
          return;
        }
        total.value = res.count
        console.log("record length:", json.length);
        allnfts.value.push(...json);
        done();
      });
    };

    return {
      page_size,
      model: ref(null),
      options: [
        {
          label: "Recent Created",
          value: "recent",
        },
        {
          label: "Oldest",
          value: "oldest",
        },
        {
          label: "Price: Low to High",
          value: "l2h",
        },
        {
          label: "Price: High to Low",
          value: "h2l",
        },
        {
          label: "Most Liked",
          value: "most_like",
        },
        {
          label: "Least Liked",
          value: "least_like",
        }
      ],
      allnfts,
      my_load,
      q: inject("q"),
      left_drawer,
      editor_sel,
      most_liked,
      price,
      free,
      free_seller,
      bronze_seller,
      silver_seller,
      gold_seller,
      ruby_seller,
      sort_by,
      price_options: [
        { label: "Buy Now", value: "buynow", color: "secondary" },
        { label: "Free", value: "free", color: "secondary" },
      ],
      total
    };
  },
  watch: {
    editor_sel: function (n, o) {
      this.do_query();
    },
    most_liked: function (n, o) {
      this.do_query();
    },
    price: function (n, o) {
      this.do_query();
    },
    free_seller: function (n, o) {
      this.do_query();
    },
    bronze_seller: function (n, o) {
      this.do_query();
    },
    silver_seller: function (n, o) {
      this.do_query();
    },
    gold_seller: function (n, o) {
      this.do_query();
    },
    ruby_seller: function (n, o) {
      this.do_query();
    },
    sort_by: function (n, o) {
      this.do_query();
    },
  },
  methods: {
    do_query() {
      this.allnfts = [];
      this.$refs.my_scroll.resume();
      this.my_load(1, (stop) => void 0);
    },    

    pdate(dstr) {
      try {
        const date = new Date(dstr);
        return new Intl.DateTimeFormat("en-US").format(
          date
        );
      } catch (e) {
        console.log(e);
        return "";
      }
    },
  },
};
</script>
